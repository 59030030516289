import React, { useState, useEffect } from 'react';
import { icons, images } from './assets/assets';
import "./Work.css";
import ProjectPreview from './components/ProjectPreview/ProjectPreview';

const Work = () => {
  const [isGrid, setIsGrid] = useState(true);
  const initialRotation = { x: 0, y: 0 };
  const [rotation, setRotation] = useState(initialRotation);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (!isMobile) {
      const handleMouseMove = (e) => {
        const x = -(e.clientX / window.innerWidth - 0.5) * 40;
        const y = (e.clientY / window.innerHeight - 0.5) * 40;
        setRotation({ x, y });
      };

      const handleMouseLeave = () => {
        setRotation(initialRotation); // Reset rotation to initial state
      };

      document.addEventListener('mousemove', handleMouseMove);
      return () => {
        document.removeEventListener('mousemove', handleMouseLeave);
      };
    }
  }, [isMobile, initialRotation]);

  useEffect(() => {
    if (isMobile) {
      const handleOrientation = (event) => {
        // Adjust rotation values based on device orientation
        let x, y;

        // Map device orientation to desired movement of the image
        if (event.beta > 0) {
          // Device raised from bottom
          y = event.beta / 90 * 20; // Move image down when device is raised from bottom
        } else {
          // Device raised from top
          y = -event.beta / 90 * 20; // Move image up when device is raised from top
        }

        if (event.gamma > 0) {
          // Device raised from left
          x = event.gamma / 90 * 20; // Move image right when device is raised from left
        } else {
          // Device raised from right
          x = -event.gamma / 90 * 20; // Move image left when device is raised from right
        }

        setRotation({ x, y });
      };

      window.addEventListener('deviceorientation', handleOrientation);

      return () => {
        window.removeEventListener('deviceorientation', handleOrientation);
      };
    }
  }, [isMobile]);




  const isGridClicked = () => {
    setIsGrid(!isGrid);
  }

  return (
    <div style={{ display: "flex", gap: "0px", flexDirection: "column", width: "100%" }}>
      {/* <div style={{ position: "fixed", top: "0", width: "100%", background: "rgba(255, 255, 255, 0.20)", zIndex: "999", backdropFilter: "blur(6.599999904632568px)" }}>
        <NavBar />
      </div> */}
      <div className='work'>
        <div className='work-main'>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <div className='work-subdesc'>Hey there 👋🏼!</div>
            <div className='work-desc'>I'm Prashant, an engineer turned product designer. Fueled by simplicity, music, and the world.</div>
          </div>
          <div className="banner-img-container" style={{ perspective: "1000px" }}>
            <img className="banner-img" src={images.workbanner} alt="Banner Image" style={{
              transform: `rotateY(${rotation.x}deg) rotateX(${rotation.y}deg)`
            }} />
          </div>
        </div>
        <div className='work-section'>
          <div className='work-section-header'>
            <div className='work-section-left'>
              <div className='work-section-head'>Works</div>
              <div className='work-section-desc'>Some of my past work</div>
            </div>
            <div className='work-section-button' onClick={isGridClicked}>
              {isGrid ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.499674" y="0.50065" width="11.0116" height="10.8946" rx="1.66658" fill="black" stroke="white" strokeWidth="0.333332" />
                <rect x="0.499674" y="12.1686" width="10.6253" height="11.3326" rx="1.66658" fill="black" stroke="white" strokeWidth="0.333332" />
                <rect x="12.488" y="0.50065" width="11.0116" height="10.8946" rx="1.66658" fill="black" stroke="white" strokeWidth="0.333332" />
                <rect x="12.488" y="12.4577" width="11.0116" height="10.8946" rx="1.66658" fill="black" stroke="white" strokeWidth="0.333332" />
              </svg> :
                <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.8" y="0.8" width="18.7565" height="19.254" rx="2.92591" fill="black" stroke="white" strokeWidth="0.6" />
                  <rect x="6.44355" y="5.94453" width="18.7565" height="19.254" rx="2.92591" fill="black" stroke="white" strokeWidth="0.6" />
                </svg>}
            </div>
          </div>
          <div className={"project-tiles" + (isGrid ? "" : " grid")}>
            <ProjectPreview
              imgSrc={images.CryptoCred}
              header="CryptoCred"
              desc="Effortlessly generate tamper-proof digital certificates for any occasion. Streamline verification with ease."
              link={"https://www.figma.com/file/dtZ076m410IuyBQCa8snvR/Cryptocred?type=design&node-id=32%3A2&mode=design&t=pgFmzrpnH5AHRsN8-1"}
            />
            <ProjectPreview
              imgSrc={images.Bashable}
              header="Bashable"
              desc="Designed a B2C subscription platform for creators to connect directly with fans through exclusive content."
              link={"https://www.figma.com/file/kZfkRHlUgetm4444YnDClq/bashable.art?type=design&node-id=478%3A112&mode=design&t=a7yrItKamqHAE1Yv-1"}
            />
            <ProjectPreview
              imgSrc={images.Rzist}
              header="Rzist"
              desc="Revamped e-commerce store: Enhanced UX for smoother shopping."
              link={"https://www.figma.com/file/UOVgb60nUu0vhbLcAQu8Hc/Rzist?type=design&node-id=0%3A1&mode=design&t=li3Byb9JjO0Yf5zR-1"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work;
