import React from 'react';
import { Routes, Route } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import NavBar from './components/NavBar/NavBar';
import Bits from './pages/Bits/Bits';
import Stox from './content/Bits/Stox/Stox';
import Footer from './components/Footer/Footer';
import Work from "./Work"


function PageLayout() {
  return (
    <div className='pageLayout' style={{width:"100%"}}>
      <SpeedInsights/>
      <NavBar/>
      <Routes>
        <Route path="/" element={<Work />} />
        <Route path="/stox" element={<Stox />} />
        <Route path="/bits" element={<Bits />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default PageLayout;
