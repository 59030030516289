import React, { useRef, useEffect, useState } from 'react';
import "./Stox.css"
import { icons, images } from '../../../assets/assets'

const Stox = () => {
  const [activeTab, setActiveTab] = useState('Column Filtering');


  return (
    <div className='stox'>
      <div className='stox-header'>
        <div className='stox-header-detail'>
          <div className='stox-header-title'>Stock Screener - <a>Stox.io</a></div>
          <div className='stox-header-desc'>Designed this as a client project, short and fast iteration to achieve the desired functionality </div>
        </div>
        <div className='stox-header-nature' ><img src={images.Client} /></div>
      </div>
      <div className='stox-banner'><img src={images.Stoxbanner} /></div>
      <div className='stox-body'>
        <div className='stox-context'>
          <div className='stox-context-additional'>
            <div className='stox-context-title'>Context</div>
            <div className='stox-context-desc'>Filtering through stocks with such complexity is never been easy for someone wanting to filter stocks using variable values.
              This short project of mine takes you through my <a>design process that leds to client satisfaction and utmost functionality with no additional</a> iterations.</div>
          </div>
        </div>
        <div className='stox-steps'>
          <div className='stox-step'>
            <div className='stox-step-additional'>
              <div className='stox-step-title'>Problem Statement</div>
              <div className='stox-step-desc'>Re-designing the current stock screener to make sure it is accessible, visually sound, quick and keeping variable search intact.</div>
            </div>
          </div>
          <div className='stox-step'>
            <div className='stox-step-additional'>
              <div className='stox-step-title'>Research and Exploration</div>
              <div className='stox-step-desc'>In order to better understand about the client requirement with the very little information provided, I have had to look through several references to gain more insights about it. Soon I realised it’s called stock screener which basically filters out stocks for you as per the filter values set.</div>
            </div>
            <div className='stox-step-frame'>
              <div className='stox-step-frame-image'><img src={images.Stoxexisting} /></div>
              <div className='stox-step-frame-caption'>Existing deployed design</div>
            </div>
          </div>
          <div className='stox-step'>
            <div className='stox-step-additional'>
              <div className='stox-step-title'>Problems Identified</div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
                <div className='stox-step-desc'>1. How do I make the stock screener accessible on every device?
                </div>
                <div className='stox-step-desc'>
                  2. How do user can quickly filter through stocks using existing variable search?
                </div>
              </div>
            </div>
          </div>
          <div className='stox-step' style={{ gap: "8px" }}>
            <div className='stox-step-additional'>
              <div className='stox-step-title'>Ideation and Solutions</div>
            </div>
            <div className='stox-step-tabs'>
            <div className='stox-step-handle'>
                <div
                  className={`stox-step-handle-toggle ${activeTab === 'Column Filtering' ? 'selected' : ''}`}
                  onClick={() => setActiveTab('Column Filtering')}
                >
                  Column Filtering
                </div>
                <div
                  className={`stox-step-handle-toggle ${activeTab === 'Variable Search' ? 'selected' : ''}`}
                  onClick={() => setActiveTab('Variable Search')}
                >
                  Variable Search
                </div>
              </div>
              {activeTab === 'Column Filtering' && (<div className='stox-step-preview'>
                <div className='stox-step-preview-detail'>
                  <div className='stox-step-preview-header'>
                    <div className='stox-step-preview-title'>Column Filtering</div>
                    <div className='stox-step-preview-desc'>To fit such a large content on small screens is quite a challenge as we can’t just eliminate columns as those are the foundation of such a product, so compromise with that was a no choice for me.</div>
                  </div>
                  <div style={{display:"flex", gap:"4px", flexDirection:"column"}}>
                  <div className='stox-step-preview-solution-title'>Solution</div>
                  <div className='stox-step-preview-solution-desc'>- Users can toggle which column to be there in the table along-side some most important columns.</div>
                  </div>
                </div>
                <div className='stox-step-preview-media'><img src={images.Stoxfilter} /></div>
              </div>)}
              {activeTab === 'Variable Search' && (<div className='stox-step-preview'>
                <div className='stox-step-preview-detail'>
                  <div className='stox-step-preview-header'>
                    <div className='stox-step-preview-title'>Varibale Search</div>
                    <div className='stox-step-preview-desc'>To allow users to screen stocks by inputing variable values was considerably a tricky problem to handle, as there was complexity associated with that as you gotta scroll up, input values and get results back on table.</div>
                  </div>
                  <div style={{display:"flex", gap:"4px", flexDirection:"column"}}>
                  <div className='stox-step-preview-solution-title'>Solution</div>
                  <div className='stox-step-preview-solution-desc'>- Integration of <a>toggle-able search bar</a> for each column to filter stocks quickly.</div>
                  </div>
                </div>
                <div className='stox-step-preview-media'><img src={images.Stoxvariable} /></div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stox