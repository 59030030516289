import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import "./Bits.css";
import { icons, images } from '../../assets/assets';

// Import other BitPage components as needed

const Bits = () => {
    return (
            <div className='bits'>
                <div className='bits-section'>
                    <div className='bits-section-head'>Bits</div>
                    <div className='bits-section-desc'>Rapid Design Iteration Work</div>
                </div>
                <div className='bits-container'>
                    <div className='bits-item'>
                        <Link to="/stox">
                            <img src={images.Stox} alt="Bit" />
                        </Link>
                    </div>
                    <div className='bits-item'>
                        <Link to="/stox">
                            <img src='https://i.pinimg.com/474x/91/91/68/9191682d198cd26220ba4dff56cda344.jpg' alt="Bit" />
                        </Link>
                    </div>
                    <div className='bits-item'>
                        <Link to="/stox">
                            <img src='https://i.pinimg.com/474x/91/91/68/9191682d198cd26220ba4dff56cda344.jpg' alt="Bit" />
                        </Link>
                    </div>
                    <div className='bits-item'>
                        <Link to="/stox">
                            <img src='https://i.pinimg.com/474x/8c/78/38/8c7838b92336d939d67553823facbf0c.jpg' alt="Bit" />
                        </Link>
                    </div>
                    {/* Add other bits similarly with Link components */}
                </div>
            </div>
    );
}

export default Bits;
