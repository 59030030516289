import React, { useState } from 'react';
import "./ProjectPreview.css"
import { icons, images } from '../../assets/assets';
import Figma from "../../assets/icons/Figma.svg"

const ProjectPreview = ({ imgSrc, header, desc, link }) => {
    const [isHovered, setIsHovered] = useState(false);

    const onButtonHover = () => {
        setIsHovered(true);
    }

    const onButtonLeave = () => {
        setIsHovered(false);
    }

    return (
        <div className='project-preview' >
            <div className='project-preview-upper'>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <img className='project-preview-img' src={imgSrc} alt="Project Preview" />
                </a>
            </div>
            <div className='project-preview-bottom' >
                <div className='project-preview-desc'>
                    <div className='project-preview-header'>
                        {header}
                    </div>
                    <div className='project-preview-body'>
                        {desc}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectPreview
