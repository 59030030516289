
import Empathise from './icons/images/Empathize.png';
import Define from './icons/images/Define.png';
import Ideation from './icons/images/Ideation.png';
import Design from './icons/images/Design.png';
import Avatar from "./icons/images/avatar.png";
import Rzist from "./icons/images/Rzist.png";
import CryptoCred from "./icons/images/CryptoCred.png";
import Bashable from "./icons/images/Bashable.png";
import clientwork from "./icons/images/clientWork.gif";
import stox from "./icons/images/stox.png"
import stoxbanner from "./icons/images/Stoxbanner.png"
import stoxexisting from "./icons/images/stox-previous.png"
import stoxfilter from "./icons/images/Stox-filter.png"
import stoxvariable from "./icons/images/Stox-variablesearch.png"


import workbanner from "./icons/images/work-banner.png"

// Export them as named exports
export const images = {
  Empathise:Empathise,
  Define:Define,
  Ideation:Ideation,
  Design:Design,
  Avatar:Avatar,
  workbanner:workbanner,
  Rzist:Rzist,
  CryptoCred:CryptoCred,
  Bashable: Bashable,
  Client: clientwork,
  Stox: stox,
  Stoxbanner: stoxbanner,
Stoxexisting: stoxexisting,
Stoxfilter: stoxfilter,
Stoxvariable:stoxvariable
};

export const icons = {

};
